import {
  expireCookie,
  getCookie,
  sprintf
} from 'application/src/js/functions.js';

export default class PendingReservation {
  constructor(args) {
    this.notify = args.notify;
    this.translations = translations.pendingReservation;

    this.cookieName = 'ma_pr';
    this.excludeUrlRegex = /^.*\/smartContract\/(order|orderSummary|orderSuccess)\/.*$/;
    this.deleteAtUrl = /^.*\/smartContract\/orderSuccess\/.*$/;

    this.init();
  }

  init() {
    if (getCookie(this.cookieName)) {
      if (location.pathname.match(this.excludeUrlRegex) === null) {
        const smartContractReservation = JSON.parse(getCookie(this.cookieName));

        this.notify.send(`
          <p>${sprintf(this.translations.productReserved, [
            `<b>
              <a href="/mundus-agri/offer/details/dynamic/${smartContractReservation.offerId}">
                ${smartContractReservation.basicName}
              </a>
            </b>`
          ])}</p>
          <a class="button small" href="/smartContract/order/${smartContractReservation.smartcontractOfferId}">
            ${this.translations.continueTheOrder}
          </a>
        `, null, {
          duration: new Date(smartContractReservation.reservationEndDate).getTime() - new Date().getTime(),
          onRemove: _ => expireCookie(this.cookieName)
        });
      }
    }

    if (location.pathname.match(this.deleteAtUrl) !== null) {
      expireCookie(this.cookieName);
    }
  }
}