import {
  Mediator
} from '../util.js';

/**
 * @description Handles the messages dropdown menu
 */
export default class Messages extends Mediator {
  constructor(args) {
    super(args);

    this.flyout = document.querySelector('.dh-flyout.messages');
    if (!this.flyout) return;
    this.trigger = document.querySelector('.dh-toggle.messages');
    this.numMessagesBubble = this.trigger.querySelector('.num-messages');
    this.messagesComponent = this.flyout.querySelector('user-messages');
    this.closer = this.flyout.querySelector('.button-close');
    this.list = this.flyout.querySelector('.messages-list');
    this.maximizeButton = this.flyout.querySelector('.button-maximize');
    this.minimizeButton = this.flyout.querySelector('.button-minimize');

    this.hasMessagesClass = 'has-messages';
    this.openClass = 'is-open';
    this.maximizeClass = 'maximized';
    this.hideClass = 'hide';
    this.isOpen = false;
    this.debugMode = true;
    this.collapseBreakpoint = 900;

    this.messagesComponentSettings = {
      cuid: this.messagesComponent.getAttribute('cuid'),
      maxHeight: this.messagesComponent.getAttribute('max-height'),
    }

    this.addEvents();
    this.addMessageComponentEvents();
  }

  addEvents() {
    window.addEventListener('click', e => {
      if (window.innerWidth < this.collapseBreakpoint) return;
      if (!this.trigger.contains(e.target) && !this.flyout.contains(e.target) && this.isOpen) {
        this.hide();
      }
    });

    this.trigger.addEventListener('click', _ => {
      if (!this.isOpen) {
        this.show();
        this.messagesComponent.updateMessageStatus();
      } else {
        this.hide();
      }
    });

    this.closer.addEventListener('click', _ => this.hide());

    this.maximizeButton.addEventListener('click', e => {
      this.maximizeButton.classList.add(this.hideClass);
      this.minimizeButton.classList.remove(this.hideClass);
      this.flyout.classList.add(this.maximizeClass);

      const tpl = `
        <user-messages
          cuid="${this.messagesComponentSettings.cuid}"
          appearance="default"
          poll="1"
          show-filter="1"
          show-attachments="1">
          <div style="padding: 1rem;">${this.spinnerTemplate}</div>
        </user-messages>
      `;

      const componentContainer = this.messagesComponent.parentElement;
      componentContainer.removeChild(this.messagesComponent);
      componentContainer.insertAdjacentHTML('beforeend', tpl);

      this.messagesComponent = this.flyout.querySelector('user-messages');
      this.addMessageComponentEvents();
    });

    this.minimizeButton.addEventListener('click', e => {
      this.minimizeButton.classList.add(this.hideClass);
      this.maximizeButton.classList.remove(this.hideClass);
      this.flyout.classList.remove(this.maximizeClass);

      const tpl = `
        <user-messages
          cuid="${this.messagesComponentSettings.cuid}"
          max-height="${this.messagesComponentSettings.maxHeight}"
          appearance="minimized"
          poll="1"
          show-attachments="1">
          <div style="padding: 1rem;">${this.spinnerTemplate}</div>
        </user-messages>
      `;

      const componentContainer = this.messagesComponent.parentElement;
      componentContainer.removeChild(this.messagesComponent);
      componentContainer.insertAdjacentHTML('beforeend', tpl);

      this.messagesComponent = this.flyout.querySelector('user-messages');
      this.addMessageComponentEvents();
    });
  }

  addMessageComponentEvents() {
    this.messagesComponent.addEventListener('afterLoad', e => {
      const unseenMessageAmount = e.detail.unseenMessages.length + e.detail.unseenReplies.length;
      this.setNumUnseenMessages(unseenMessageAmount);
      this.toggleHasUnseenMessages(unseenMessageAmount);
      if (this.isOpen) this.messagesComponent.updateMessageStatus(false);
    });

    this.messagesComponent.addEventListener('afterSave', e => {
      const unseenMessageAmount = e.detail.unseenMessages.length + e.detail.unseenReplies.length;
      this.setNumUnseenMessages(unseenMessageAmount);
      this.toggleHasUnseenMessages(unseenMessageAmount);
      if (this.isOpen) this.messagesComponent.updateMessageStatus(false);
    });
  }

  show() {
    this.flyout.classList.remove(this.hideClass);
    this.trigger.classList.add(this.openClass);
    this.isOpen = true;
    this.publish('show');
  }

  hide() {
    this.flyout.classList.add(this.hideClass);
    this.trigger.classList.remove(this.openClass);
    this.isOpen = false;
  }

  get spinnerTemplate() {
    return `
      <div class="spinner">
        <div>
          <div></div>
          <div></div>
        </div>
      </div>
    `;
  }

  /**
   * @param {number} numMessages amount of unseen messages
   */
  toggleHasUnseenMessages(numMessages) {
    this.trigger.classList[numMessages ? 'add' : 'remove'](this.hasMessagesClass);
  }

  /**
   * @param {number} numMessages amount of unseen messages
   */
  setNumUnseenMessages(numMessages) {
    this.numMessagesBubble.innerHTML = numMessages;
  }
}