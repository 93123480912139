import {
  Mediator
} from '../util.js';

export default class BackofficeNavigation extends Mediator {
  constructor() {
    super();
    this.trigger = document.querySelector('.dh-toggle.admin');
    this.menu = document.querySelector('.dh-flyout.admin');

    if (!this.menu || !this.trigger) return undefined;

    this.closer = this.menu.querySelector('.button-close');
    this.menuItems = this.menu.querySelectorAll('.bo-menu-item.has-submenu');

    this.hideClass = 'hide';
    this.expandedClass = 'is-open';
    this.isOpen = false;
    this.collapseBreakpoint = 640;
    this.addEvents();
  }

  addEvents() {
    window.addEventListener('click', e => {
      if (window.innerWidth < this.collapseBreakpoint) return;
      if (!this.trigger.contains(e.target) && !this.menu.contains(e.target) && this.isOpen) {
        this.hide();
      }
    });

    this.trigger.addEventListener('click', _ => this.toggleMenu());
    this.closer.addEventListener('click', _ => this.hide());

    [...this.menuItems].map(menuItem => {
      const trigger = menuItem.querySelector('a');
      const submenu = menuItem.querySelector('.bo-submenu');

      if (submenu) {
        trigger.addEventListener('click', e => {
          if (menuItem.classList.contains(this.expandedClass)) {
            menuItem.classList.remove(this.expandedClass);
            submenu.classList.add(this.hideClass);
          } else {
            [...this.menuItems].map(item => {
              if (item.classList.contains(this.expandedClass)) {
                item.classList.remove(this.expandedClass);
                item.querySelector('.bo-submenu').classList.add(this.hideClass);
              }
            });
            submenu.classList.remove(this.hideClass);
            menuItem.classList.add(this.expandedClass);
          }
        });
      }
    });
  }

  toggleMenu() {
    this.isOpen ? this.hide() : this.show();
  }

  show() {
    this.menu.classList.remove(this.hideClass);
    this.trigger.classList.add(this.expandedClass);
    this.isOpen = true;
    this.publish('show');
  }

  hide() {
    if (!this.menu) return;
    this.menu.classList.add(this.hideClass);
    this.trigger.classList.remove(this.expandedClass);
    this.isOpen = false;
  }
}