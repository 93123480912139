import {
  Mediator,
  ModalWindow
} from 'application/src/js/util.js';

/**
 * @description Modal window for notifications with channel=2
 * use text as content or, if empty, use template (welcome message on first login)
 * @param {object} translations
 */
export default class EventNotificationsModal extends Mediator {
  constructor(args) {
    super(args);

    this.translations = args.translations;

    this.element = document.querySelector('#modal-notifications');
    this.contentContainer = this.element.querySelector('.notification-content');
    this.outerHeadlineContainer = this.element.querySelector('.modal-head');
    this.innerSuperHeadlineContainer = this.outerHeadlineContainer.querySelector('.modal-head h2');
    this.innerHeadlineContainer = this.outerHeadlineContainer.querySelector('.modal-head h3');
    this.infoHeadlineContainer = this.outerHeadlineContainer.querySelector('.modal-head h4');
    this.subHeadlineContainer = this.outerHeadlineContainer.querySelector('.modal-head .sub-headline');
    this.modal = new ModalWindow(this.element);

    this.notification = null;
  }

  addEvents() {
    [...this.contentContainer.querySelectorAll('a')].map(t => {
      t.addEventListener('click', e => {
        if (!this.notification) return;
        if (this.notification.click === 0) {
          this.publish('action', this.notification.user_notification_id);
        }
      });
    });
  }

  /**
   * @description fired to open the modal window
   * @param {object} notification - notification json object
   */
  show(notification) {
    this.notification = notification;
    this.render();
    this.modal.open();
  }

  render() {
    if (!this.notification) return;

    // if text is not empty, use text as modal content
    if (this.notification.text) {
      this.contentContainer.innerHTML = this.notification.text;
    }

    // show link if exists
    if (this.notification.link) {
      this.contentContainer.innerHTML = this.contentContainer.innerHTML + `<br><br><a href="${this.notification.link}">${this.translations.moreInfo}</a>`;
    }

    this.addEvents();
  }
}