import {
  ajax
} from './../functions.js'

/**
 * @description show/hide the cookie notice
 * send xhr for saving (set cookie)
 */
export default class CookieNotice {
  constructor(args) {
    this.element = document.querySelector('#cookie-notice');
    if (!this.element || window.cookieNotice) return false;
    this.confirmButton = this.element.querySelector('.button');
    this.unconfirmClass = 'unconfirmed';
    this.confirmClass = 'confirmed';
    this.requestUrl = '/app/ajax/acceptCookie';
    this.sent = false;
    this.isVisible = false;

    this.addEvents();
    setTimeout(() => this.show(), 2500);
  }

  addEvents() {
    this.confirmButton.addEventListener('click', e => {
      if (this.sent) return;
      this.sent = true;
      this.hide();

      ajax(this.requestUrl, {})
        .catch(e => {
          this.sent = false;
          console.warn(e);
        });
    })
  }

  show() {
    if (this.isVisible) return;
    this.isVisible = true;
    this.element.classList.remove(this.confirmClass);
    this.element.classList.add(this.unconfirmClass);
  }

  hide() {
    if (!this.isVisible) return;
    this.isVisible = false;
    this.element.classList.remove(this.unconfirmClass);
    this.element.classList.add(this.confirmClass);
  }
}