export default {
    "uic": {
        "notifications": {
            "duration": 3500,
            "types": {
                "alert": {
                    "color": "#fff",
                    "bgColor": "#e74c3c",
                    "borderColor": "#e74c3c",
                    "closerColor": "#fff"
                },
                "warning": {
                    "color": "#fff",
                    "bgColor": "#f39c12",
                    "borderColor": "#f39c12",
                    "closerColor": "#fff"
                },
                "success": {
                    "color": "#fff",
                    "bgColor": "#27ae60",
                    "borderColor": "#27ae60",
                    "closerColor": "#fff"
                }
            }
        }
    }
}