import {
  DataStorage,
  Mediator
} from '../util.js';
import {
  ajax,
  getElapsedTimeText
} from '../functions.js';
import reducer from './event-notifications/reducer.js';
// import EventNotificationsSettings from './event-notifications/event-notifications-settings.js';
import EventNotificationsModal from './event-notifications/event-notifications-modal.js';

/**
 * @description Loads and displays notifications
 */
class EventNotifications extends Mediator {
  constructor(args) {
    super(args);

    this.flyout = document.querySelector('.dh-flyout.events');
    if (!this.flyout) return;
    this.trigger = document.querySelector('.dh-toggle.events');
    this.numNotificationsBubble = this.trigger.querySelector('.num-notifications');
    this.closer = this.flyout.querySelector('.dh-flyout-head .button-close');
    this.list = this.flyout.querySelector('.notifications-list');
    this.noResultsMessage = this.flyout.querySelector('.no-results-message');

    this.hasNotificationsClass = 'has-notifications';
    this.openClass = 'is-open';
    this.hideClass = 'hide';
    this.translations = translations.eventNotifications || eventNotificationsTranslations;
    this.isOpen = false;
    this.randomId = Math.floor(Math.random() * 100) * new Date().getTime();
    this.interval = null; // the interval for refreshing/reloading notifications
    this.intervalTime = 1 * 60 * 1000;
    this.collapseBreakpoint = 900;
    // this.settingsModal = new EventNotificationsSettings();
    this.notificationsModal = new EventNotificationsModal({
      translations: this.translations.modal
    });
    this.requestRunning = false;
    this.debugMode = false;

    this.store = new DataStorage();
    this.addEvents();

    if (this.pageIsVisible) {
      this.startPolling();
      this.load();
    }
  }

  load() {
    if (this.requestRunning) return;
    this.requestRunning = true;

    ajax('/app/notification/getNotifications', {
        // limit: 10,
        // offset: 0
      })
      .then(response => {
        this.requestRunning = false;

        this.store.init(reducer, {
          standardNotifications: response.data.filter(n => {
            if (n.channel == 1) {// || n.text === null) {
              if (n.text) return n;

              const sourceKey = notificationMap.source[n.source];
              const typeKey = notificationMap.sourceType[n.source_type];
              const actionKey = notificationMap.sourceAction[n.source_action];

              if (this.translations.standard[sourceKey] &&
                this.translations.standard[sourceKey][typeKey] &&
                this.translations.standard[sourceKey][typeKey][actionKey]) return n;
            }
          }),
          overlayNotifications: response.data.filter(n => {
            if (!this.getCookie('blockModalNotifications') && (n.channel == 2 || n.text !== null)) {
              return n;
            }
          })
        });

        this.render();
      })
      .catch(e => {
        this.requestRunning = false;
        if (this.debugMode && e) console.log(e);
      });
  }

  getCookie(name) {
    let match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)'));
    return match ? match[1] : null;
  }

  save(notificationIds, actionType) {
    this.requestRunning = true;

    return ajax(`/app/notification/${actionType}`, {
      user_notification_id: notificationIds,
    })
      .then(_ => {
        this.requestRunning = false;

        this.store.dispatch({
          type: actionType == 'read' ? 'MARK_AS_READ' : 'MARK_AS_CLICKED',
          payload: notificationIds
        });

        this.render();
      })
      .catch(e => {
        this.requestRunning = false;
        if (this.debugMode && e) console.log(e);
      });
  }

  addEvents() {
    window.addEventListener('click', e => {
      if (window.innerWidth < this.collapseBreakpoint) return;
      if (!this.trigger.contains(e.target) && !this.flyout.contains(e.target) && this.isOpen) {
        this.hide();
      }
    });

    ['pageshow', 'mouseenter', 'visibilitychange', 'focus'].map(eventType => {
      document.addEventListener(eventType, _ => {
        if (this.pageIsVisible) this.setActivePage();
      });
    });

    this.trigger.addEventListener('click', e => {
      if (!this.isOpen) {
        this.show();

        if (typeof this.store.getState().standardNotifications == 'undefined') return;

        const unreadTextNotificationIds = this.store.getState().standardNotifications.reduce((ids, notification) => {
          if (notification.seen === 0) ids.push(notification.user_notification_id);
          return ids;
        }, []);

        if (unreadTextNotificationIds.length) {
          this.save(unreadTextNotificationIds, 'read');
        }
      } else {
        this.hide();
      }
    });

    this.closer.addEventListener('click', e => this.hide());

    this.notificationsModal.subscribe('action', notificationId => this.save([notificationId], 'click'));
    this.notificationsModal.subscribe('disable', _ => document.cookie = 'blockModalNotifications=1; expires=Fri, 31 Dec 9999 23:59:59 GMT;');
    this.notificationsModal.subscribe('enable', _ => document.cookie = 'blockModalNotifications=; expires=Thu, 01 Jan 1970 00:00:01 GMT;');
  }

  get pageIsVisible() {
    return document.visibilityState == 'visible';
  }

  get pageIsActive() {
    return parseInt(localStorage.getItem('notifyInstId')) === this.randomId;
  }

  setActivePage() {
    localStorage.setItem('notifyInstId', this.randomId);
  }

  startPolling() {
    if (this.interval) clearInterval(this.interval);

    this.interval = setInterval(_ => {
      if (this.pageIsActive) this.load();
    }, this.intervalTime);
  }

  hasTextLinks(notification) {
    if (!notification.text) return false;
    return notification.text.match(/<a[\s]+([^>]+)>/ig) !== null;
  }

  render() {
    const state = this.store.getState();

    if (!state.standardNotifications.length && !state.overlayNotifications.length) return;

    this.noResultsMessage.classList[state.standardNotifications.length ? 'add' : 'remove'](this.hideClass);

    if (state.standardNotifications.length) {
      this.list.innerHTML = '';

      state.standardNotifications.map(n => {
        const hasTextLinks = this.hasTextLinks(n);
        const notificationElement = document.createElement(hasTextLinks ? 'div' : 'a');
        this.list.insertAdjacentElement('beforeend', notificationElement);

        new EventNotification({
          parent: this,
          element: notificationElement,
          data: n,
          translations: this.translations,
          hasTextLinks: hasTextLinks
        });
      });
    }

    const unreadOverlayNotifications = this.getUnreadNotifications(state.overlayNotifications);

    if (unreadOverlayNotifications.length) {
      const notification = unreadOverlayNotifications[0];
      this.notificationsModal.show(notification);
      this.save([notification.user_notification_id], 'read');
    }

    this.setNumNotifications();
    this.toggleHasUnreadNotifications();
  }

  toggleHasUnreadNotifications() {
    if (this.getUnreadNotifications(this.store.getState().standardNotifications).length) {
      this.trigger.classList.add(this.hasNotificationsClass);
    } else {
      this.trigger.classList.remove(this.hasNotificationsClass);
    }
  }

  setNumNotifications() {
    this.numNotificationsBubble.innerHTML = this.store.getState().standardNotifications.filter(n => n.seen === 0).length;
  }

  getUnreadNotifications(notifications) {
    return notifications.filter(n => n.seen === 0);
  }

  show() {
    this.flyout.classList.remove(this.hideClass);
    this.trigger.classList.add(this.openClass);
    this.isOpen = true;
    this.publish('show');
  }

  hide() {
    this.flyout.classList.add(this.hideClass);
    this.trigger.classList.remove(this.openClass);
    this.isOpen = false;
  }

  onNotificationClick(notificationId) {
    return this.save([notificationId], 'click');
  }
}

const notificationMap = {
  source: {
    0: 'dedere',
    1: 'timeline',
    2: 'mailmanager',
    3: 'novellaAgri',
    4: 'mundusAgri',
    5: 'calendarium'
  },
  sourceType: {
    0: 'info',
    1: 'post',
    2: 'comment',
    3: 'mailing',
    4: 'businessGroup',
    5: 'contactRequest',
    6: 'submittedOffer',
    7: 'smartcontract',
    8: 'reminder',
  },
  sourceAction: {
    0: 'text',
    1: 'newComment',
    2: 'newPost',
    3: 'sent',
    4: 'welcome',
    5: 'acceptedInvitationDynamic',
    6: 'acceptedInvitationContract',
    7: 'receivedReply',
    8: 'receivedMessage',
    9: 'receivedOffer',
    10: 'receivedOfferFeedback',
    11: 'receivedBusinessGroupInvitation',
    12: 'newDocument',
    13: 'orderReceived',
    14: 'orderCancelRequested',
    15: 'orderCancelAccepted',
    16: 'orderCancelRejected',
    17: 'canceledByDedere',
    18: 'offerExpiresSoon'
  }
};

const getNotificationText = notification => {
  const t = translations.eventNotifications || eventNotificationsTranslations,
    n = notification;

  const sourceKey = notificationMap.source[n.source];
  const typeKey = notificationMap.sourceType[n.source_type];
  const actionKey = notificationMap.sourceAction[n.source_action];

  return t.standard[sourceKey][typeKey][actionKey]
    .replace('${firstname}', n.firstname ? `<b title="${n.companyname || ``}">${n.firstname}</b>` : ``)
    .replace('${lastname}', n.lastname ? `<b title="${n.companyname || ``}">${n.lastname}</b>` : ``)
    .replace('${companyname}', n.companyname ? `<b>${n.companyname}</b>` : ``)
    .replace('${mailing_name}', n.mailing_name ? `<b>${n.mailing_name}</b>` : ``)
    .replace('${basicname}', n.basicname ? `<b>${n.basicname}</b>` : ``);
};

const getNotificationTargetUrl = notification => {
  const t = translations.eventNotifications || eventNotificationsTranslations,
    n = notification;

  // static link
  if (n.link) return n.link;

  // comment on post
  if ((n.source == 1 && n.source_type == 1 && n.source_action == 1) ||
    // comment reply
    (n.source == 1 && n.source_type == 2 && n.source_action == 1) ||
    // new post
    (n.source == 1 && n.source_type == 1 && n.source_action == 2)) return `/mundus-agri/timeline/index/${n.timeline_company_id}/${n.timeline_id}`;

  // mailing sent via mailmanager
  if (n.source == 2 && n.source_type == 3 && n.source_action == 3) return `/mailmanager/mailing/${n.target_object_id}/information`;

  // registered on dedere
  if ((n.source == 0 && n.source_type == 0 && n.source_action == 4) ||
    // registered on dedere-calendarium
    (n.source == 5 && n.source_type == 0 && n.source_action == 4) ||
    // registered on mundus-agri
    (n.source == 4 && n.source_type == 0 && n.source_action == 4) ||
    // registered on novella-agri
    (n.source == 3 && n.source_type == 0 && n.source_action == 4)) return `/abo`;

  // accepted contract offer application on membership
  if (n.source == 0 && n.source_type == 4 && n.source_action == 6 && n.contract_offer_request_id) return `/mundus-agri/offer/contractOfferDetails/${n.target_object_id}`;

  //accepted dynamic offer application on membership
  if (n.source == 0 && n.source_type == 4 && n.source_action == 5 && n.dynamic_offer_request_id) return `/mundus-agri/offer/dynamicOfferDetails/${n.target_object_id}`;

  //received business group invitation
  if (n.source == 0 && n.source_type == 4 && n.source_action == 11) return `/mundus-agri/addresslist`;

  // received contact request
  if (n.source == 4 && n.source_type == 5 && n.source_action == 7 ||
    n.source == 4 && n.source_type == 5 && n.source_action == 8) return `/incursio/manage/1`;

  // received offer
  if (n.source == 4 && n.source_type == 6 && n.source_action == 9 ||
    n.source == 4 && n.source_type == 6 && n.source_action == 10) return `/mundus-agri/offer/requestDetails/${n.target_object_id}`;

  // offer expiration reminder
  if( n.source == 4 && n.source_type == 8 && n.source_action == 18) return `/mundus-agri/offer/dynamicOfferDetails/${n.source_object_id}`;

  // smart contract
  if (n.source == 4 && n.source_type == 7 &&
    (
      n.source_action == 12 ||
      n.source_action == 13 ||
      n.source_action == 14 ||
      n.source_action == 15 ||
      n.source_action == 16 ||
      n.source_action == 17)) return `/smartContract/details/${n.target_object_id}`;

  return null;
};

class EventNotification {
  constructor(args) {
    this.parent = args.parent;
    this.element = args.element;
    this.data = args.data;
    this.translations = args.translations;
    this.hasTextLinks = args.hasTextLinks;
    this.redirect = true;
    this.isBusy = false;
    const targetUrl = getNotificationTargetUrl(this.data);
    this.targetUrl = targetUrl && !this.hasTextLinks ? targetUrl : null;

    if (this.targetUrl) this.element.setAttribute('href', this.targetUrl);

    this.render();
  }

  addEvents() {
    if (this.targetUrl || this.hasTextLinks) {
      // var isTouchDevice = 'ontouchstart' in document.documentElement;

      this.element.addEventListener('click', e => {//isTouchDevice ? 'touchstart' : 'mousedown', e => {
        e.preventDefault();

        if (this.isBusy) return;
        this.isBusy = true;

        if (typeof e.button !== 'undefined') {
          if (e.button === 0) {
            this.redirect = true;
          } else {
            this.redirect = false;
          }
        }

        if (this.data.click === 0) {
          this.parent.onNotificationClick(this.data.user_notification_id)
            .then(_ => {
              if (this.redirect) {
                location.href = e.target.tagName.toLowerCase() === 'a' && e.target.href ? e.target.href : this.targetUrl;
              }
            });
        } else {
          if (this.redirect) {
            location.href = e.target.tagName.toLowerCase() === 'a' && e.target.href ? e.target.href : this.targetUrl;
          }
        }
      });
    }
  }

  render() {
    this.element.innerHTML = this.template;
    this.element.setAttribute('data-notificition-id', this.data.user_notification_id);
    this.element.classList[this.targetUrl ? 'add' : 'remove']('linked');
    this.element.classList[this.data.seen ? 'add' : 'remove']('read');
    this.element.classList[this.data.click ? 'add' : 'remove']('clicked');

    this.addEvents();
  }

  get template() {
    const elapsedTimeText = getElapsedTimeText(this.data.notice_timestamp * 1000, this.translations.elapsedTime);

    return `
      <span class="action">${this.data.text || getNotificationText(this.data)}</span>&nbsp;
      <span class="date-time">${isNaN(elapsedTimeText) ? elapsedTimeText : this.data.notice_date}</span>
    `;
  }
}

export default EventNotifications;