import {
  ajax
} from 'application/src/js/functions.js';

export default class SidebarMenu {
  constructor(args) {
    this.element = document.querySelector('.sidebar-menu');
    this.trigger = document.querySelector('.dh-toggle.sidebar');
    if (!this.element || !this.trigger) return;
    this.content = this.element.querySelector('.sb-content');
    this.menus = this.element.querySelectorAll('.sb-menu');
    this.closer = this.element.querySelector('.sb-closer');
    this.bg = this.element.querySelector('.sb-bg');

    this.visibleClass = 'sb-visible';
    this.centerClass = 'sb-center';
    this.leftClass = 'sb-left';
    this.fadeInClass = 'sb-fade-in';
    this.fadeOutClass = 'sb-fade-out';
    this.bodyLockClass = 'lock-position';

    this.debugMode = true;
    this.menuInstances = [];
    this.currentMenuId = 0;

    [...this.menus].map(menuElement => {
      const args = {
        parent: this,
        element: menuElement,
        debugMode: this.debugMode
      };

      this.menuInstances.push(menuElement.getAttribute('data-menu-id') == '4' ? new ProductCategoryMenu(args) : new Menu(args));
    });

    this.addEvents();
  }

  addEvents() {
    this.closer.addEventListener('click', _ => {
      this.hide();
      this.unlockBodyPosition();
      this.showMenu(0);
    });

    this.bg.addEventListener('click', _ => {
      this.hide();
      this.unlockBodyPosition();
      this.showMenu(0);
    });

    this.trigger.addEventListener('click', _ => {
      this.lockBodyPosition();
      this.show();
    });
  }

  show() {
    this.element.classList.add(this.visibleClass);
    this.content.classList.add(this.centerClass);
    this.bg.classList.add(this.fadeInClass);
  }

  hide() {
    this.element.classList.remove(this.visibleClass);
    this.content.classList.remove(this.centerClass);
    this.bg.classList.remove(this.fadeInClass);
  }

  lockBodyPosition() {
    document.body.style.top = -1 * window.scrollY;
    document.documentElement.classList.add(this.bodyLockClass);
  }

  unlockBodyPosition() {
    document.body.style.top = 0;
    document.documentElement.classList.remove(this.bodyLockClass);
  }

  showMenu(targetMenuId) {
    if (targetMenuId === this.currentMenuId) return;

    this.menuInstances.map(menu => {
      if (menu.menuId === targetMenuId) menu.slideIn();
      if (menu.menuId === this.currentMenuId) menu.slideOutLeft();
    });

    this.currentMenuId = targetMenuId;
  }

  hideMenu(targetMenuId) {
    if (targetMenuId === this.currentMenuId) return;

    this.menuInstances.map(menu => {
      if (menu.menuId === targetMenuId) menu.slideIn();
      if (menu.menuId === this.currentMenuId) menu.slideOutRight();
    });

    this.currentMenuId = targetMenuId;
  }
}

class Menu {
  constructor(args) {
    this.parent = args.parent;
    this.element = args.element;

    this.menuId = parseInt(this.element.getAttribute('data-menu-id'));
    this.parentMenuId = parseInt(this.element.getAttribute('data-parent-menu-id')) || 0;

    this.backButton = this.element.querySelector('.sb-menu-top');
    this.menuItems = this.element.querySelectorAll('.sb-item');

    this.centerClass = 'sb-center';
    this.leftClass = 'sb-left';

    this.init();
  }

  init() {
    this.initMenuItems();
    this.addEvents();
  }

  initMenuItems() {
    [...this.menuItems].map(menuItemElement => {
      new MenuItem({
        element: menuItemElement,
        parent: this
      });
    });
  }

  addEvents() {
    if (this.backButton) {
      this.backButton.addEventListener('click', _ => this.parent.hideMenu(this.parentMenuId, this.menuId));
    }
  }

  slideOutLeft() {
    this.element.classList.add(this.leftClass);
    this.element.classList.remove(this.centerClass);
  }

  slideOutRight() {
    this.element.classList.remove(this.leftClass);
    this.element.classList.remove(this.centerClass);
  }

  slideIn() {
    this.element.classList.remove(this.leftClass);
    this.element.classList.add(this.centerClass);
  }

  onItemClick(targetMenuId) {
    this.parent.showMenu(targetMenuId);
  }
}

class ProductCategoryMenu extends Menu {
  constructor(args) {
    super(args);
  }

  init() {
    this.loadSubCategories()
      .then(response => this.groupSubCategories(response.data))
      .then(categoryGroups => this.addSubCategoryOptions(categoryGroups))
      .then(_ => {
        this.initMenuItems();
        this.addEvents();
      })
      .catch(e => {
        if (this.debugMode && e) console.log(e);
      });
  }

  loadSubCategories() {
    return ajax('/app/ajax/getSubCategories', {
      useappjsonmodel: true
    });
  }

  groupSubCategories(categories) {
    return categories.reduce((aggregated, category) => {
      if (aggregated[category.name]) {
        aggregated[category.name].globalCategoryIds.push(category.global_category_id);
        aggregated[category.name].categories.push(category);
      } else {
        aggregated[category.name] = {
          globalCategoryIds: [category.global_category_id],
          categories: [category],
          name: category.name
        }
      }
      return aggregated;
    }, {});
  }

  addSubCategoryOptions(categoryGroups) {
    Object.values(categoryGroups).map(categoryGroup => {
      this.element.querySelector('.sb-group').insertAdjacentHTML('beforeend', `
        <li>
          <a class="sb-item" href="/search?t=offers&show=offers&categories=${categoryGroup.globalCategoryIds.join(',')}">
            <span>${categoryGroup.name}</span>
          </a>
        </li>
      `);
    });
  }
}

class MenuItem {
  constructor(args) {
    this.parent = args.parent;
    this.element = args.element;
    this.debugMode = args.debugMode;

    this.targetMenuId = parseInt(this.element.getAttribute('data-target-menu-id'));

    this.addEvents();
  }

  addEvents() {
    if (!isNaN(this.targetMenuId)) {
      this.element.addEventListener('click', _ => {
        this.parent.onItemClick(this.targetMenuId);
      });
    }
  }
}