import {
  Mediator
} from '../util.js';

export default class LanguageSelection extends Mediator {
  constructor(args) {
    super(args);

    this.flyout = document.querySelector('.dh-flyout.language');
    if (!this.flyout) return;
    this.trigger = document.querySelector('.dh-toggle.language');
    this.closer = this.flyout.querySelector('.button-close');
    this.openClass = 'is-open';
    this.hideClass = 'hide';
    this.isOpen = false;
    this.collapseBreakpoint = 900;

    this.addEvents();
  }

  addEvents() {
    window.addEventListener('click', e => {
      if (window.innerWidth < this.collapseBreakpoint) return;
      if (!this.trigger.contains(e.target) && !this.flyout.contains(e.target) && this.isOpen) {
        this.hide();
      }
    });

    this.trigger.addEventListener('click', e => {
      this.isOpen ? this.hide() : this.show();
    });

    this.closer.addEventListener('click', e => this.hide());
  }

  show() {
    this.flyout.classList.remove(this.hideClass);
    this.trigger.classList.add(this.openClass);
    this.isOpen = true;
    this.publish('show');
  }

  hide() {
    this.flyout.classList.add(this.hideClass);
    this.trigger.classList.remove(this.openClass);
    this.isOpen = false;
  }
}