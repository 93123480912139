import '../scss/application.scss';
import '@babel/polyfill';
// import './assets.js';

import GlobalExceptionLogger from './debug/global-exception-logger.js';
import Navigation from './dedere-header/navigation.js';
import MainMenu from './dedere-header/main-menu.js';
import Messages from './dedere-header/messages.js';
import LanguageSelection from './dedere-header/language-selection.js';
import SidebarMenu from './dedere-header/sidebar-menu.js';
import EventNotifications from './dedere-header/event-notifications.js';
import BackofficeNavigation from './dedere-header/backoffice-navigation.js';
import SearchBar from './dedere-header/search-bar.js';
import CookieNotice from './cookie/cookie-notice.js';
import PendingReservation from './smart-contract/pending-reservation.js';

import settings from 'application/src/js/settings.js';
import Notifications from 'application/src/js/ui-collection/notifications.js';

import {
  FlyoutMenu,
  ModalWindow,
  SpinnerOverlay,
  VisibilityWatcher
} from './util.js';

// require.context('application/src/images', false, /\.(png|svg|jpe?g|gif)$/);
// require.context('application/src/images/icons', true, /\.(png|svg|jpe?g|gif)$/);
// require.context('application/src/images/email', true, /\.(png|svg|jpe?g|gif)$/);
// require.context('application/src/images/modal/modal-logistic-info', false, /\.(png|svg|jpe?g|gif)$/);

new GlobalExceptionLogger();

window.bLazy;
window.notify;

window.addEventListener('DOMContentLoaded', _ => {
  window.notify = new Notifications(settings.uic.notifications);

  new PendingReservation({
    notify: window.notify
  });

  [...document.querySelectorAll('.trade-item.tpl-2')].map(tradeItem => {
    if (tradeItem.querySelector('.flyout-menu')) {
      new FlyoutMenu({
        element: tradeItem.querySelector('.flyout-menu')
      });
    }
  });

  const header = document.querySelector('#dedere-header');

  ['resize', 'scroll'].map(eventType => {
    window.addEventListener(eventType, _ => {
      if (window.scrollY > 0) {
        document.body.style.paddingTop = header.getBoundingClientRect().height + 'px';
        header.style.position = 'fixed';
      } else {
        document.body.style.paddingTop = 0;
        header.style.position = 'relative';
      }
    });
  });

  if (typeof Blazy !== 'undefined') {
    window.bLazy = new Blazy({ offset: 200 });
  };

  new VisibilityWatcher({
    elements: [
      document.querySelector('section.learn-more')
    ]
  });

  new MainMenu();
  new SidebarMenu();
  new CookieNotice();

  // Upgrade Membership Modal
  if (document.querySelector('#modal-upgrade-membership')) {
    const upgradeMembershipModal = new ModalWindow(document.querySelector('#modal-upgrade-membership'), {
      backgroundIsCloseTrigger: true
    });

    [...document.querySelectorAll('.show-upgrade-membership-modal')].map(button => {
      button.addEventListener('click', e => upgradeMembershipModal.open());
    });
  }

  // modal for displaying info about delivery units
  const modalLogisticInfo = new ModalWindow(document.querySelector('#modal-logistic-info'), {
    openButtonClass: 'open-modal-logistic-info',
    backgroundIsCloseTrigger: true,
    lockBodyPosition: true
  });

  if (window.bLazy) {
    modalLogisticInfo.subscribe('open', _ => window.bLazy.revalidate());
  }

  // modal for displaying info about incoterms
  new ModalWindow(document.querySelector('#modal-incoterms-info'), {
    openButtonClass: 'open-modal-incoterms-info',
    backgroundIsCloseTrigger: true,
    lockBodyPosition: true
  });

  if (document.querySelector('.stock-exchange-bar')) {
    import('application/src/js/dedere-header/stock-exchange-bar.js').then(({
      default: StockExchangeBar
    }) => {
      new StockExchangeBar({
        loader: new SpinnerOverlay({
          element: document.querySelector('.stock-exchange-bar .overlay'),
          position: 'absolute',
          visible: true,
          zIndex: 2
        }),
        translations: translations.stockExchangeBar,
      });
    });
  }

  import('application/src/js/footer/back-to-top.js').then(({
    default: BackToTop
  }) => new BackToTop());

  new Navigation({
    backofficeNavigation: document.querySelector('.dh-toggle.admin') ? new BackofficeNavigation() : null,
    languageSelection: document.querySelector('.dh-toggle.language') ? new LanguageSelection() : null,
    eventNotifications: document.querySelector('.dh-toggle.events') ? new EventNotifications() : null,
    messages: document.querySelector('.dh-toggle.messages') ? new Messages() : null,
    searchBar: new SearchBar({
      translations: translations.searchBar
    }),
  });
})

/**
 * this function is being called after Youtube Api is loaded and ready to be used
 * fires a custom event "youtubeApiInitialized" on the window object
 */
window.onYouTubePlayerAPIReady = () => {
  const evt = new CustomEvent('youtubeApiInitialized', {});
  window.dispatchEvent(evt);
}

/**
 * DEPRECATED. DO NOT USE! Use getLanguageShort() from functions.js
 * Get the selected language
 * @return String
 */
window.getSelectedLanguage = _ => {
  return document.querySelector('meta[name=language]').getAttribute('content');
}