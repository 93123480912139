/**
 * navigation handler that hides a navigation when another is opened
 * @param {BackofficeNavigation} args.backofficeNavigation
 * @param {LanguageSelection} args.languageSelection
 * @param {EventNotifications} args.eventNotifications
 * @param {Messages} args.messages
 * @param {SearchBar} args.searchBar
 */
 export default class Navigation {
  constructor(args) {
    this.backofficeNavigation = args.backofficeNavigation;
    this.languageSelection = args.languageSelection;
    this.eventNotifications = args.eventNotifications;
    this.messages = args.messages;
    this.searchBar = args.searchBar;

    this.addEvents();
  }

  addEvents() {
    if (this.backofficeNavigation) {
      this.backofficeNavigation.subscribe('show', _ => {
        if (this.eventNotifications) this.eventNotifications.hide();
        if (this.languageSelection) this.languageSelection.hide();
        if (this.messages) this.messages.hide();
        if (this.searchBar) this.searchBar.hide();
      });
    }

    if (this.languageSelection) {
      this.languageSelection.subscribe('show', _ => {
        if (this.backofficeNavigation) this.backofficeNavigation.hide();
        if (this.eventNotifications) this.eventNotifications.hide();
        if (this.messages) this.messages.hide();
        if (this.searchBar) this.searchBar.hide();
      });
    }

    if (this.eventNotifications) {
      this.eventNotifications.subscribe('show', _ => {
        if (this.backofficeNavigation) this.backofficeNavigation.hide();
        if (this.languageSelection) this.languageSelection.hide();
        if (this.messages) this.messages.hide();
        if (this.searchBar) this.searchBar.hide();
      });
    }

    if (this.messages) {
      this.messages.subscribe('show', _ => {
        if (this.backofficeNavigation) this.backofficeNavigation.hide();
        if (this.languageSelection) this.languageSelection.hide();
        if (this.eventNotifications) this.eventNotifications.hide();
        if (this.searchBar) this.searchBar.hide();
      });
    }

    if (this.searchBar) {
      this.searchBar.subscribe('show', _ => {
        if (this.backofficeNavigation) this.backofficeNavigation.hide();
        if (this.languageSelection) this.languageSelection.hide();
        if (this.eventNotifications) this.eventNotifications.hide();
        if (this.messages) this.messages.hide();
      });
    }
  }
}