import {
  HorizontalMenuBar
} from 'application/src/js/util.js';

import {
  isTouchDevice
} from 'application/src/js/functions.js';

export default class MainMenu {
  constructor(args) {
    this.element = document.querySelector('#main-menu');
    this.menuItems = [...this.element.querySelectorAll('.menu-item')];

    this.init();
  }

  init() {
    new HorizontalMenuBar({
      element: this.element.querySelector('.horizonal-menu-bar')
    });

    if (isTouchDevice()) return;

    this.menuItems.map(menuItemElement => {
      new MenuItem({
        parent: this,
        element: menuItemElement
      });
    });
  }
}

class MenuItem {
  constructor(args) {
    this.parent = args.parent;
    this.element = args.element;
    this.drop = this.element.querySelector('.drop');

    this.showTimeout = null;
    this.leaveTimeout = null;

    this.hoverClass = 'hover';

    this.addEvents();
  }

  addEvents() {
    this.element.addEventListener('mouseenter', e => {
      if (this.leaveTimeout) clearTimeout(this.leaveTimeout);
      if (this.showTimeout) clearTimeout(this.showTimeout);
      this.showTimeout = setTimeout(_ => {
        if (this.drop) this.drop.style.minWidth = `${this.element.getBoundingClientRect().width}px`;
        this.element.classList.add(this.hoverClass);
      }, 200);
    });

    this.element.addEventListener('mouseleave', e => {
      if (this.showTimeout) clearTimeout(this.showTimeout);
      if (this.leaveTimeout) clearTimeout(this.leaveTimeout);
      this.showTimeout = setTimeout(_ => this.element.classList.remove(this.hoverClass), 200);
    });
  }
}