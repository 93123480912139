import {
  ajax
} from '../functions.js';

/**
 * @description catches errors and unhandledrejection events and logs them in the database
 * uses sourcemapped-stacktrace module in order to apply source maps to JS stack traces
 */
export default class GlobalExceptionLogger {
  constructor(args) {
    if (typeof window.sourceMappedStackTrace === 'undefined') return;
    this.addEvents();
    this.logFrontendException = logFrontendException.bind(this);
  }

  addEvents() {
    window.addEventListener('unhandledrejection', e => {
      if (!e.reason) return false;
      if (!e.reason.stack) return false;
      this.mapStackTrace(`${e.type} ${e.reason.message}`, e.reason.stack);
    });

    window.addEventListener('error', e => {
      if (!e.error) return false;
      if (!e.error.stack) return false;
      this.mapStackTrace(e.message, e.error.stack);
    });
  }

  /**
   * @description map source maps to JS stack traces
   */
  mapStackTrace(message, stack) {
    window.sourceMappedStackTrace.mapStackTrace(stack, mappedStack => {
      const stackTrace = mappedStack.map(m => m.trim()).join('\n');
      this.logFrontendException(message, stackTrace);
    });
  }
}

/**
 * @description log exception in database
 */
export const logFrontendException = (message, stackTrace) => {
  ajax('/app/ajax/addFrontendException', {
      error_message: message,
      stack_trace: stackTrace
    }).then(r => {})
    .catch(e => {});
}