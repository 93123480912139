const reducer = (state, action) => {
  let nextState = Object.assign({}, state);

  switch(action.type) {
    case 'MARK_AS_READ':
      [...nextState.standardNotifications, ...nextState.overlayNotifications].map(notification => {
        if(action.payload.indexOf(notification.user_notification_id) != -1) notification.seen = 1;
      });
      return nextState;

    case 'MARK_AS_CLICKED':
      [...nextState.standardNotifications, ...nextState.overlayNotifications].map(notification => {
        if(action.payload.indexOf(notification.user_notification_id) != -1) notification.click = 1;
      });
      return nextState;

    default: return nextState;
  }
}

export default reducer;