import {
  ajax,
  getCookie,
  setCookie
} from 'application/src/js/functions.js';

import {
  Mediator
} from '../util.js';

/**
 * @param {object} args.translations
 */
export default class SearchBar extends Mediator {
  constructor(args) {
    super();

    this.outerContainer = document.querySelector('header .column-search');
    this.container = document.querySelector('header .search-bar-container');
    if (!this.outerContainer || !this.container) return;

    this.translations = args.translations;

    this.inputField = this.container.querySelector('.search-field');
    this.toggle = document.querySelector('.dh-toggle.search');
    this.submitButton = this.container.querySelector('.trigger');
    this.errorMessage = this.container.querySelector('.input-wrap span');
    this.outputContainer = this.container.querySelector('.results');

    this.openClass = 'is-open';
    this.focusClass = 'focus';
    this.hasInputClass = 'has-input';
    this.currentResults = [];
    this.isExpanded = false;
    this.products = [];
    this.debugMode = false;
    this.cookieName = 'ma_lsv'; // ma_lsv = MundusAgri_LastSearchValues

    this.isOpen = false;

    const cookie = getCookie(this.cookieName);
    this.recentSearchValues = cookie ? cookie.split(',').map(searchValue => atob(searchValue)) : [];

    this.addEvents();
    this.toggleHasInputClass(this.hasInput);
  }

  show() {
    this.outerContainer.style.display = 'block';
    this.toggle.classList.add(this.openClass);
    this.inputField.focus();
    this.isOpen = true;
    this.publish('show');
  }

  hide() {
    this.outerContainer.style.display = 'none';
    this.toggle.classList.remove(this.openClass);
    this.isOpen = false;
  }

  addEvents() {
    // close search results on click outside
    document.body.addEventListener('click', e => {
      if (this.isExpanded) {
        if (!this.container.contains(e.target) &&
          this.typeDropdown != document.activeElement &&
          this.inputField != document.activeElement) {
          this.close();
        }
      }

      if (this.isOpen) {
        if (!document.querySelector('header').contains(e.target)) {
          this.hide();
        }
      }
    });

    this.toggle.addEventListener('click', _ => {
      if (this.isOpen) {
        this.hide();
      } else {
        this.show();
      }
    });

    this.inputField.addEventListener('focus', e => {
      if (this.products.length || this.recentSearchValues) {
        this.render();
        this.open();
      }
    });

    ['keydown', 'keypress'].map(eventType => {
      this.inputField.addEventListener(eventType, e => {

        const results = [...this.outputContainer.querySelectorAll('a')];
        if (!results.length) return;
        const activeResult = this.outputContainer.querySelector(`a.${this.focusClass}`);
        const activeIndex = results.indexOf(activeResult);

        // arrow up
        if (e.key == 'ArrowUp') {
          e.preventDefault();
          if (activeResult) activeResult.classList.remove(this.focusClass);
          if (activeIndex > 0) results[activeIndex - 1].classList.add(this.focusClass);
        }

        // arrow down or tabulator
        if (e.key == 'ArrowDown' || e.key === 'Tab') {
          if (activeResult) activeResult.classList.remove(this.focusClass);

          if (results[activeIndex + 1]) {
            e.preventDefault();
            if (results[activeIndex + 1]) results[activeIndex + 1].classList.add(this.focusClass);
            return;
          }
        }
      });
    });

    ['keyup', 'paste'].map(eventType => {
      this.inputField.addEventListener(eventType, e => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'Tab') {
          e.preventDefault();
          return;
        }

        this.toggleHasInputClass(this.hasInput);

        // if less than 2 chars, empty and hide results container
        if (!this.hasInput && e.key !== 'Enter') {
          this.currentResults = [];
          this.render();
          return;
        }

        // go to search results page on "ENTER"
        if (e.key === 'Enter') {
          const activeResult = this.outputContainer.querySelector(`a.${this.focusClass}`);

          if (activeResult) {
            e.preventDefault();
            activeResult.click();
            return;
          }

          this.saveAsCookie();
          this.goToSearchResults();
          return;
        }

        // hide input on "ESCAPE"
        if (e.key === 'Escape') {
          this.container.classList.remove(this.openClass);
          results.map(r => r.classList.remove(this.focusClass));
          return;
        }

        this.wait().then(_ => this.search())
      });
    });

    this.inputField.addEventListener('empty', e => {
      this.currentResults = [];
      this.close();
      this.toggleHasInputClass(this.hasInput);
    });

    this.inputField.addEventListener('focus', e => {
      if (this.currentResults.length) this.open();
      this.toggleHasInputClass(this.hasInput);

      if (this.products.length) return;

      ajax('/app/ajax/getProducts', { group_by_products: true })
        .then(response => {
          this.products = response.data;
          this.search();
        })
        .catch(e => {
          if (this.debugMode) console.log(e);
        });
    });

    this.submitButton.addEventListener('click', e => {
      if (!this.hasInput) return;
      this.saveAsCookie();
      this.goToSearchResults();
    });
  }

  search() {
    return new Promise((resolve, reject) => {
      if (this.inputField.value.trim().length < 3) resolve([]);

      const searchString = escapeRegExp(this.inputField.value.trim().toLowerCase());
      const slices = searchString.split(' ');
      const regex = new RegExp(`(${slices.join('|')})`, 'gi');

      // filter products that have at least 1 match
      let results = this.products.filter(product => product.name.search(regex) > -1);
      let results2 = [...results];

      resolve(results2.slice(0, 6));
    }).then(results => {
      if (results.length || this.recentSearchValues.length) {
        this.currentResults = results;
        this.render();
        this.open();
      } else {
        this.currentResults = [];
        this.close();
      }
    })
      .catch(e => {
        this.currentResults = [];
        this.close();
        console.log(e);
      });
  }

  wait() {
    return new Promise((resolve, reject) => {
      setTimeout(_ => resolve(), 50);
    });
  }

  render() {
    this.outputContainer.innerHTML = '';

    const searchString = this.inputField.value.trim();
    const slices = searchString.split(' ');

    if (this.currentResults.length) {
      const resultsOut = document.createElement('div');
      this.outputContainer.insertAdjacentElement('beforeend', resultsOut);

      this.currentResults.map(resultsData => {
        const resultsElement = document.createElement('a');
        resultsOut.insertAdjacentElement('beforeend', resultsElement);
        resultsData.replaced = resultsData.name;

        slices.map(slice => {
          const matches = resultsData.replaced.match(new RegExp(slice, 'gi'));

          if (matches && matches.length) {
            resultsData.replaced = resultsData.replaced.replace(new RegExp(slice, 'gi'), `<b>${matches[0]}</b>`);
          }
        });

        new SearchResult({
          parent: this,
          element: resultsElement,
          data: resultsData
        });
      });
    }

    if (this.recentSearchValues.length) {
      const recentSearchOut = document.createElement('div');
      this.outputContainer.insertAdjacentElement('beforeend', recentSearchOut);

      recentSearchOut.insertAdjacentHTML('beforeend', `
        <b>${this.translations.recentlySearched}:</b>
        ${this.recentSearchValues.map(searchValue => `
          <a tabindex="-1" href="/search?v=${replaceSpecialChars(searchValue)}">${searchValue}</a>
        `).join('')}
      `);
    }
  }

  toggleHasInputClass(hasInput) {
    this.container.classList[hasInput ? 'add' : 'remove'](this.hasInputClass);
  }

  open() {
    this.container.classList.add(this.openClass);
    this.isExpanded = true;
    this.inputField.focus();
  }

  close() {
    this.container.classList.remove(this.openClass);
    this.isExpanded = false;
    [...this.outputContainer.querySelectorAll('a')].map(r => r.classList.remove(this.focusClass));
  }

  /**
   * @returns {boolean}
   */
  get hasInput() {
    return this.inputField.value.trim().length > 2;
  }

  /**
   * @description saves the search values as comma seperated base64 strings in cookie
   */
  saveAsCookie() {

	let inputValue = this.inputField.value.trim();
	inputValue     = inputValue.replace(/[“”]/g, '"');
    const currentSearchValue = inputValue;
	
    // remove search value if already present
    if (this.recentSearchValues.indexOf(currentSearchValue) >= 0) {
      this.recentSearchValues.splice(this.recentSearchValues.indexOf(currentSearchValue), 1);
    }

    // add new search value in front
    this.recentSearchValues.unshift(currentSearchValue);

    // max 5 results
    if (this.recentSearchValues.length > 5) this.recentSearchValues.pop();

    // set cookie with search values as csv
    setCookie(this.cookieName, this.recentSearchValues.map(value => btoa(value)).join(','));
  }

  /**
   * @description go to the search results page
   * passes the encoded search value
   */
  goToSearchResults() {
    location.href = `/search?v=${replaceSpecialChars(this.inputField.value.trim())}`;
  }
}

/**
 * @param {SearchBar} args.parent
 * @param {node} args.element
 * @param {object} args.data
 */
class SearchResult {
  constructor(args) {
    this.parent = args.parent;
    this.element = args.element;
    this.data = args.data;

    this.render();
  }

  render() {
    this.element.innerHTML = this.template;
    this.element.href = `/search?v=${replaceSpecialChars(this.data.name.trim())}&products=${this.data.global_product_basic_id}`;
  }

  get template() {
    return `
      ${this.data.replaced}
    `;
  }
}

/**
 * @param {string} str
 * @returns {string}
 */
const replaceSpecialChars = str => {
  return str
    .replace(/\s{2,}/g, '%20')
    .replace(/\s/g, '%20')
    .replace(/\&/g, '%26')
    .replace(/\+/g, '%2B')
    .replace(/\"/g, '%22');
};

/**
 * @param {string} str
 * @returns {string}
 */
const escapeRegExp = str => {
  return str.replace(/[.*+?^&${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}